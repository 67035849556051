<template>
	<div>
		<b-form-select v-model="selected"
		               :options="options"
		               :class="validateError != '' ? 'box-border-color':''"
		               @input="handleInput"/>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
	</div>
</template>

<script>
	import GradeService from "@/services/GradeService";
	
	export default {
		props: {
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			}
		},
		watch: {
			value: function (newValue) {
				this.selected = newValue
			},
		},
		data() {
			return {
				selected: this.value,
				options: [{value: null, text: this.$t('select') }]
			}
		},
		methods: {
			getOptions() {
				const config = {
					params: {
						limit: -1
					},
				};
				GradeService.getGradeConversions(config)
				            .then(response => {
					            const data = response.data.data;
					            data.map(item => {
						            this.options.push({
							            value: item.id,
							            text: item.name,
						            });
					            });
				            })
			},
			handleInput(event) {
				this.$emit('input', event)
			}
		},
		created() {
			this.getOptions();
		}
	};
</script>
