<template>
    <div>
        <div class="row data-form">
            <div class="col-12">
                <div class="mb-4">
                    <h6 class="text-uppercase mb-0">{{$t('grade_conversion_info').toUpper()}}</h6>
                </div>
            </div>
            <div class="col-12 col-md-12">
                <ValidationObserver ref="updateForm">
                    <div class="row">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="gradeConversionId" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('grade_conversion_name')">
                                    <grade-conversions-selectbox
                                        :validate-error="errors[0]"
                                        v-model="defineForm.gradeConversionId">
                                    </grade-conversions-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="fromGrade" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('grade_conversion_range_start')">
                                    <b-form-input
                                        v-model="defineForm.fromGrade"
                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="toGrade" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('grade_conversion_range_end')">
                                    <b-form-input
                                        v-model="defineForm.toGrade"
                                        :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ValidationProvider name="bauGrades" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('bau_grades')">
                                    <grade-selectbox
                                        value-type="grade"
                                        :grading-system-id-required="false"
                                        :validate-error="errors[0]"
                                        v-model="defineForm.grade">
                                    </grade-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <b-form-group>
                                <b-button variant="primary" @click="sendForm">{{$t('save')}}</b-button>
                            </b-form-group>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
<script>
import {ValidationObserver, ValidationProvider} from "vee-validate";
import GradeSelectbox from "@/components/interactive-fields/GradeSelectbox";
import GradeService from "@/services/GradeService";
import GradeConversionsSelectbox from "@/components/interactive-fields/GradeConversionsSelectbox";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,

        GradeSelectbox,
        GradeConversionsSelectbox,
    },
    props: {
        id: {
            default: null,
            type: Number
        },
        grade_conversion_id: {
            default: null,
            type: Number
        }
    },
    data() {
        return {
            defineForm: {
                gradeConversionId: null,
                fromGrade: null,
                toGrade: null,
                grade: null
            }
        }
    },
    metaInfo() {
        return {
            title: this.$t("grade_conversion_update_title")
        }
    },
    methods: {
        getFormData() {
            this.defineForm.gradeConversionId = this.grade_conversion_id;
            if(!this.id){
                return;
            }
            GradeService.showGradeConversionGrades(this.id).then(response => {
                let data = response.data.data
                this.defineForm.gradeConversionId = data.grade_conversion_id
                this.defineForm.fromGrade = data.from_grade
                this.defineForm.toGrade = data.to_grade
                this.defineForm.grade = data.grade
            }).catch(e => {
                this.showErrors(e)
            })
        },

        async sendForm() {
            const isValid = await this.$refs.updateForm.validate();
            if (isValid) {
                let formData = {
                    grade_conversion_id: this.defineForm.gradeConversionId,
                    from_grade: this.defineForm.fromGrade,
                    to_grade: this.defineForm.toGrade,
                    grade: this.defineForm.grade
                }

                if(this.id>0){
                    GradeService.updateGradeConversionGrades(this.id, formData).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit('hide')
                    }).catch(e => {
                        this.showErrors(e,this.$refs.updateForm)
                    })
                }
                else {
                    GradeService.storeGradeConversionGrades(formData).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$emit('hide')
                    }).catch(e => {
                        this.showErrors(e,this.$refs.updateForm)
                    })
                }
            }
        }
    },
    watch: {
        id: {
            handler: function (val) {
                this.getFormData(val)
            }
        }
    }
};
</script>
